import React from 'react'
import styled from 'styled-components'

const UserDetails = () => {
  return (
    <div className="container">
        <div className="container overflow-auto h-[350px]">
        <table className="table text-white" style={{tableLayout : "auto"}}>
            <thead>
              <tr className='bg-[#f37e20] sticky top-0'>
                <th className="text-nowrap">S.No.</th>
                <th className="text-nowrap">Devotte Name</th>
                <th className="text-nowrap">Email</th>
                
              </tr>
            </thead>
            <tbody>
              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>1</td>
                <td>Rohit Singh</td>
                <td>Rohit@gmail.com</td>
                
              </tr>
              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>1</td>
                <td>Rohit Singh</td>
                <td>Rohit@gmail.com</td>
                
              </tr>
              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>1</td>
                <td>Rohit Singh</td>
                <td>Rohit@gmail.com</td>
                
              </tr>
              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>1</td>
                <td>Rohit Singh</td>
                <td>Rohit@gmail.com</td>
                
              </tr>

            </tbody>
          </table>
        </div> 
    </div>
  )
}

export default UserDetails