import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sitebar from './dashboardComponent/Sitebar';
import Dashboard from './Dashboard';
import ProductContent from './ProductContent';
import {FaUserCircle} from 'react-icons/fa';
import bgcover from './images/dashboarBg.png';
import Contact from './Contact';
import UserDetails from './UserDetails';
import Donation from './Donation';
import News from './News';
import Gallery from './Gallery';
import Login from './Login';
import { useState } from 'react';
import Signup from './Signup';

function App() {
  const [login, setLogin] = useState(false);
  return (
    <div>
       <BrowserRouter>
          <div style={{background: "#f37e20", color: "#f1f1f1"}}>
            <div className="container user flex justify-between py-8 px-9 mb-2">
              <div>
                  <p><strong>Prajjal Saxena</strong></p>
              </div>
              <div>
                
                  <FaUserCircle style={{fontSize: "30px"}}/>
              </div>
          </div>
          </div>
         <div className="flex">
            {!login && <div className="site">
              <Sitebar/>
            </div>}
            <div className={`${!login ? "rgt-site" : "w-full"} py-4 px-4`} style={{background : `url(${bgcover})no-repeat center center/cover`, backgroundPosition: "center", backgroundSize : "100% 100%", height: "80vh"}}>
              <Routes>
              {!login && (<>
                          <Route exact path='/login' element={<Login/>} />
                          <Route exact path='/sign-up' element={<Signup/>} />
                        </>)
              }
                <Route exact path='/dashboard' element={<Dashboard/>}/>
                <Route exact path='/booking' element={<ProductContent/>}/>
                <Route exact path='/contact' element={<Contact/>}/>
                <Route exact path='/user-details' element={<UserDetails/>}/>
                <Route exact path='/donation' element={<Donation/>} />
                <Route exact path='/news' element={<News/>} />
                <Route exact path='/gallery' element={<Gallery/>}/>
              </Routes>
            </div>
         </div>
       </BrowserRouter>
    </div>
  );
}

export default App;
