import React from 'react'
import styled from 'styled-components'

const Dashboard = () => {
  return (
    <Wrapper className="container">
        <div className="row justify-between">
            <div className="col-3">
                <div className="dash1 p-4 rounded-xl">
                    <p><strong>Number of Booking</strong></p>
                    <p>1000</p>
                </div>
            </div>
            <div className="col-3">
                <div className="dash1 p-4 rounded-xl">
                    <p><strong>Donation</strong></p>
                    <p>1000</p>
                </div>
            </div>
           
        </div>

       <div className="row">
         <div className="col-6">
            <div className="tabltext-white">
        <div className="container overflow-auto h-[350px]">
        <table className="table text-white" style={{tableLayout : "auto"}}>
            <thead>
              <tr className='bg-[#f37e20] sticky top-0'>
                <th className="text-nowrap">S.No.</th>
                <th className="text-nowrap">Devotte Name</th>
                <th className="text-nowrap">Email</th>
                <th className="text-nowrap">Mobile Number</th>
                <th className='text-nowrap'>Aadhar Number</th>
                <th className="text-nowrap">Address</th>
                <th className='text-nowrap'>Check in Date</th>
                <th className="text-nowrap">Check in Time</th>
                <th className="text-nowrap">Checkout Date</th>
                <th className="text-nowrap">Room Type</th>
                <th className='text-nowrap'>Number of Person</th>
                <th className="text-nowrap">Price</th>
                   
              </tr>
            </thead>
            <tbody>
              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>1</td>
                <td>Rohit Singh</td>
                <td>Rohit@gmail.com</td>
                <td>9259297316</td>
                <td>9293949404</td>
                <td>Ganga nagar Meerut</td>
                <td>5 July 2023</td>
                <td>1:00 PM</td>
                <td>6 July 2023</td>
                <td>A.C.</td>
                <td>4</td>
                <td>Rs 500</td>
                
              </tr>

              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>2</td>
                <td>Rohit Singh</td>
                <td>Rohit@gmail.com</td>
                <td>9259297316</td>
                <td>9293949404</td>
                <td>Ganga nagar Meerut</td>
                <td>5 July 2023</td>
                <td>1:00 PM</td>
                <td>6 July 2023</td>
                <td>A.C.</td>
                <td>4</td>
                <td>Rs 500</td>
            
              </tr>
              
              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>2</td>
                <td>Rohit Singh</td>
                <td>Rohit@gmail.com</td>
                <td>9259297316</td>
                <td>9293949404</td>
                <td>Ganga nagar Meerut</td>
                <td>5 July 2023</td>
                <td>1:00 PM</td>
                <td>6 July 2023</td>
                <td>A.C.</td>
                <td>4</td>
                <td>Rs 500</td>
                
              </tr>

              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>2</td>
                <td>Rohit Singh</td>
                <td>Rohit@gmail.com</td>
                <td>9259297316</td>
                <td>9293949404</td>
                <td>Ganga nagar Meerut</td>
                <td>5 July 2023</td>
                <td>1:00 PM</td>
                <td>6 July 2023</td>
                <td>A.C.</td>
                <td>4</td>
                <td>Rs 500</td>
               
              </tr>

              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>2</td>
                <td>Rohit Singh</td>
                <td>Rohit@gmail.com</td>
                <td>9259297316</td>
                <td>9293949404</td>
                <td>Ganga nagar Meerut</td>
                <td>5 July 2023</td>
                <td>1:00 PM</td>
                <td>6 July 2023</td>
                <td>A.C.</td>
                <td>4</td>
                <td>Rs 500</td>
                
              </tr>

              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>2</td>
                <td>Rohit Singh</td>
                <td>Rohit@gmail.com</td>
                <td>9259297316</td>
                <td>9293949404</td>
                <td>Ganga nagar Meerut</td>
                <td>5 July 2023</td>
                <td>1:00 PM</td>
                <td>6 July 2023</td>
                <td>A.C.</td>
                <td>4</td>
                <td>Rs 500</td>
                
              </tr>

              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>2</td>
                <td>Rohit Singh</td>
                <td>Rohit@gmail.com</td>
                <td>9259297316</td>
                <td>9293949404</td>
                <td>Ganga nagar Meerut</td>
                <td>5 July 2023</td>
                <td>1:00 PM</td>
                <td>6 July 2023</td>
                <td>A.C.</td>
                <td>4</td>
                <td>Rs 500</td>
                
              </tr>

              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>2</td>
                <td>Rohit Singh</td>
                <td>Rohit@gmail.com</td>
                <td>9259297316</td>
                <td>9293949404</td>
                <td>Ganga nagar Meerut</td>
                <td>5 July 2023</td>
                <td>1:00 PM</td>
                <td>6 July 2023</td>
                <td>A.C.</td>
                <td>4</td>
                <td>Rs 500</td>
               
              </tr>
            </tbody>
          </table>
        </div>

        </div>  
         </div>

         <div className="col-6">
           <div className="tabltext-white">
        <div className="container overflow-auto h-[350px]">
        <table className="table text-white" style={{tableLayout : "auto"}}>
            <thead>
              <tr className='bg-[#f37e20] sticky top-0'>
                <th className="text-nowrap">S.No.</th>
                <th className="text-nowrap">Devotte Name</th>
                <th className="text-nowrap">Email</th>
                <th className="text-nowrap">Mobile Number</th>
                <th className="text-nowrap">Message</th>
              </tr>
            </thead>
            <tbody>
              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>1</td>
                <td>Rohit Singh</td>
                <td>Rohit@gmail.com</td>
                <td>9259297316</td>
                <td>9293949404</td>
              </tr>

              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>1</td>
                <td>Rohit Singh</td>
                <td>Rohit@gmail.com</td>
                <td>9259297316</td>
                <td>9293949404</td>
              </tr>

              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>1</td>
                <td>Rohit Singh</td>
                <td>Rohit@gmail.com</td>
                <td>9259297316</td>
                <td>9293949404</td>
              </tr>

              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>1</td>
                <td>Rohit Singh</td>
                <td>Rohit@gmail.com</td>
                <td>9259297316</td>
                <td>9293949404</td>
              </tr>

              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>1</td>
                <td>Rohit Singh</td>
                <td>Rohit@gmail.com</td>
                <td>9259297316</td>
                <td>9293949404</td>
              </tr>

            </tbody>
          </table>
        </div>

        </div>
         </div>
       </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
.dash1{
      color: #f1f1f1;
      border: 4px solid #f37e20;
      margin-bottom: 10px;
      text-align: center;
   }
   /* .dash1{
      color: #f1f1f1;
      border: 4px solid #f37e20;
   }
   table.scrolldown {
         width: 100%;
         border-spacing: 0;
         border: 1px solid black;
      }
          
      table.scrolldown tbody, table.scrolldown thead {
         display: block;
      }   
           
      table.scrolldown tbody {
         height: 250px; 
         overflow-y: auto;
         overflow-x: hidden; 
      }
          
     
          
      tbody td, thead th {
         width : 186px;
      }
      th{
        text-align: center;
      }
      tr{
        border-bottom: 1px solid #f1f1f1;
      }
      td {
         text-align:center;
      } */



      td{
        border: 1px solid #f1f1f1;
      }
`;
export default Dashboard