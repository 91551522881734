import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components';

const Sitebar = () => {
  // const [color, changeColor] = useState('bg-[#FDEBA9]')
  // const handleChange = () =>{
  //    const dashli = document.querySelector('.dashli');
  //    dashli.classList.add("bgg")

  // }
  return (
    <Wrapper>
        <div className="container">
        <ul className='dashboard-list text-[#f1f1f1]'>
            <li className="bg-[#f37e20]"><Link to="/dashboard"><strong>Dashboard</strong></Link></li>
            <li className="bg-[#f37e20]"><Link to="/booking"><strong>Booking</strong></Link></li>
            <li className="bg-[#f37e20]"><Link to="/contact"><strong>Contact</strong></Link></li>
            <li className="bg-[#f37e20]"><Link to="/user-details"><strong>User</strong></Link></li>
            <li className="bg-[#f37e20]"><Link to="/donation"><strong>Donation</strong></Link></li>
            <li className="bg-[#f37e20]"><Link to="/news"><strong>News</strong></Link></li>
            <li className="bg-[#f37e20]"><Link to="/gallery"><strong>Gallery</strong></Link></li>
        </ul>
        </div>
       
    </Wrapper>
  )
}

const Wrapper = styled.section`
 .bgg{
   background: #000;
 }
.dashboard-list{
      list-style: none;
   }
   .dashboard-list li{
      margin-bottom: 2px;
      padding: 5px 30px;
   }
   a{
    text-decoration: none ;
   }

`;

export default Sitebar