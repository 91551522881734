import React, { useEffect } from 'react'
import {RiLockPasswordFill} from 'react-icons/ri'
import {FcGoogle} from 'react-icons/fc';
import {GrFacebookOption} from 'react-icons/gr';
import {FaTwitter} from 'react-icons/fa';
import {GrClose} from 'react-icons/gr';
import {FaUserAlt} from 'react-icons/fa';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import { gapi } from 'gapi-script';


const clientId = "1042028280523-ifgcduq543jq1n7aop18pdqcqft6eq0c.apps.googleusercontent.com";
const Login = () => {
  const onSuccess = (res) => {
    // console.log("LOGIN SUCCESS! Current user: ", res);
  }

  const onFailure = () =>{
    // console.log("LOGIN FAILED! res: ",res)
  }

  useEffect(()=>{
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: ""
      })
    };

    gapi.load('client:auth2', start);
  })
  return (
    <section>
    <div className="">
      <div className="">
        <div className="modal-header">
          <h1 className="" id=""></h1>
          
        </div>
        <div className="bg-light md:w-[45%] block m-auto p-[19px] rounded-2xl">
          <form>
            <div className="mb-3"> 
              <label className="form-label">username</label>
                <div className=''>
                  <div className='user-login absolute top-[189px] w-14'>
                    <FaUserAlt className='w-full opacity-30 md:p-1'/>
                  </div>
                    <input type="email" className="form-control pl-12" id="exampleFormControlInput1" placeholder='Type your username' required/>
                </div>
            </div>
            <div className="mb-3">
              <label className="form-label">password</label>
              <div className='relative'>
                  <div className='user-login absolute top-[10px] lg:top-[6px] w-14'>
                    <RiLockPasswordFill className='w-full opacity-30 md:p-1'/>
                  </div>
                    <input type="password" className="form-control pl-12" id="exampleFormControlInput1" placeholder='*************' required />
                </div>
            </div>
            <input type="submit" className='btn btn-warning w-100 bg-yellow-500' value="Login" />
            </form>
            <div className='text-center m-4'>
               <p>or</p>
               <p>Signup with</p>
               <div className="signup-with flex justify-center mt-3">
                  <GoogleLogin className='bg-'
                    clientId={clientId}
                    buttonText='Login'
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    cookiePolicy={'single_host_origin'}
                    isSignedIn= {true} /> 
               </div>
               <div>
                 <p><strong>Don't have an Account? <Link to='/sign-up'><span className='hover:underline'>Sign up</span></Link></strong></p>
               </div>
            </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Login