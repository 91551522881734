import React from 'react'
import styled from 'styled-components'

const News = () => {
  return (
    <Wrapper className='container'>
    <div className='text-right'>
      <button type="button" className="bg-[#f37e20] text-white py-2 px-4 rounded-md" data-bs-toggle="modal" data-bs-target="#exampleModal"><strong>Add Item</strong></button>

      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content bg-[#f8c301]">
           
          <form className='p-10'>
                <div class="form-group mb-1">
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Title"/>
                   
                </div>
                <div class="form-group mb-1">
                    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Description"/>
                </div>
                <div class="form-group mb-1">
                    <input type="date" class="form-control" id="exampleInputPassword1" />
                </div>
                <div class="form-group mb-1">
                    <input type="file" class="form-control" id="exampleInputPassword1" placeholder="Description"/>
                </div>
                <button type="submit" class="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">Submit</button>
                </form> 
          </div>
        </div>
      </div>
    </div>
    <div className="tabl py-4 text-white">
    <div className="container overflow-auto h-[350px]">
    <table className="table text-white" style={{tableLayout : "auto"}}>
        <thead>
          <tr className='bg-[#f37e20] sticky top-0'>
            <th className="text-nowrap">S.No.</th>
            <th className="text-nowrap">Title</th>
            <th className="text-nowrap">Description</th>
            <th className="text-nowrap">Date</th>
            <th className="text-nowrap">Image</th>
          </tr>
        </thead>
        <tbody>
          <tr className='hover:bg-slate-600 hover:cursor-pointer'>
            <td>1</td>
            <td>Sai Arti</td>
            <td>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem accusantium minus fuga doloremque voluptate perspiciatis praesentium, repellat ipsa voluptatum quis?</td>
            <td>20/05/2022</td>
            <td>image</td>
          </tr>

        </tbody>
      </table>
    </div>

    </div>
</Wrapper>
  )
}
const Wrapper = styled.section`
   /* .dash1{
      background: linear-gradient(90deg, #FBDA61 0%, #FDECB0 100%);
   }
   table.scrolldown {
         width: 100%;
         border-spacing: 0;
         border: 1px solid black;
      }
          
      table.scrolldown tbody, table.scrolldown thead {
         display: block;
      }   
           
      table.scrolldown tbody {
         height: 250px; 
         overflow-y: auto;
         overflow-x: hidden; 
      }
          
     th{
      width : 186px;
     }
          
      tbody td {
         width : 186px;
         border-right: 1px solid black;
      }
      td {
         text-align:center;
      }
      .btn{
        background: #000;
      } */
      td{
        border: 1px solid #f1f1f1;
      }


`
export default News