import React from 'react'
import {RiLockPasswordFill} from 'react-icons/ri'
import {FcGoogle} from 'react-icons/fc';
import {GrFacebookOption} from 'react-icons/gr';
import {FaTwitter} from 'react-icons/fa';
import {GrClose} from 'react-icons/gr';
import {FaUserAlt} from 'react-icons/fa';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Signup = () => {
  return (
    <div>
        <div className="bg-light md:w-[45%] block m-auto p-[19px] rounded-2xl">
          <form>
            <div className="mb-3"> 
              <label className="form-label">Full Name</label>
                <div className=''>

                    <input type="email" className="form-control pl-12" id="exampleFormControlInput1"  required/>
                </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Email</label>
              <div className='relative'>
                 
                    <input type="email" className="form-control pl-12" id="exampleFormControlInput1"  required />
                </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Password</label>
              <div className='relative'>
                 
                    <input type="password" className="form-control pl-12" id="exampleFormControlInput1"  required />
                </div>
            </div>
            <input type="submit" className='btn btn-warning w-100 bg-yellow-500' value="Sign Up" />
            </form>
            
        </div>
    </div>
  )
}

export default Signup