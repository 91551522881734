import axios from 'axios';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const APIData = 'https://nimittech.com/sai_api/booking.php';

const ProductContent = () => {
   const data = [{
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    adhaar_no: "",
    adhaar_card: "",
    street: "",
    city: "",
    state: "",
    pin_code: "",
    arrival_date: "",
    arrival_time: "",
    no_of_person: "",
    room_type: "",
    message: ""
   }]

  const [formData, setFormData] = useState(data);

  // FOR UPDATE THE TABLE RECORD
  // const [updateState, setUpdateState] = useState(-1);
  // const handleEdit = (id) =>{
  //     setUpdateState(id)
  // }
  // const handleUpdate = (e) => {
  //    e.preventDefault();
  //    setUpdateState(-1)
  // }

  // function Edit({elem}) {
  //   console.log("elem", elem);
  //    function handleInput(id, data) {
  //       // const newList = dashboardData.map((li)=>(
  //       //   li.id === elem.id ? {...li, [e.target.name] : e.target.value} : li
  //       // ))

  //       // setDashboardData(newList);
  //       // e.target.value
  //       const updatedItems = dashboardData.map(item => {
  //          if(item.id === id){
  //             return {...item, [data.target.name] : data.target.value}
  //          }
  //          return item;
  //       })
  //       setDashboard([...updatedItems])
  //    }

  //    return (
  //     <tr className='hover:bg-slate-600 hover:cursor-pointer text-[#000]'>
  //         <td><input type="text" name="" value={elem.id} id="" /></td>
  //         <td><input type="text" name="first_name" onChange={event => handleInput(elem.id, event)} value={elem.first_name} /></td>
  //         <td><input type="text" name="email" onChange={event => handleInput(elem.id, event)} value={elem.email} /></td>
  //         <td><input type="text" name="phone_no" onChange={event => handleInput(elem.id, event)} value={elem.phone_no}/></td>
  //         <td><input type="text" name="street" onChange={event => handleInput(elem.id, event)} value={elem.street}/></td>
  //         <td><input type="text" name="city" onChange={event => handleInput(elem.id, event)} value={elem.city}/></td>
  //         <td><input type="text" name="state" onChange={event => handleInput(elem.id, event)} value={elem.state}/></td>
  //         <td><input type="text" name="pin_code" onChange={event => handleInput(elem.id, event)} value={elem.pin_code}/></td>
  //         <td><input type="text" name="arrival_date" onChange={event => handleInput(elem.id, event)} value={elem.arrival_date}/></td>
  //         <td><input type="text" name="arrival_time" onChange={event => handleInput(elem.id, event)} value={elem.arrival_time}/></td>
  //         <td><input type="text" name="no_of_person" onChange={event => handleInput(elem.id, event)} value={elem.no_of_person}/></td>
  //         <td><input type="text" name="room_type" onChange={event => handleInput(elem.id, event)} value={elem.room_type}/></td>
  //         <td><input type="text" name="adhaar_no" onChange={event => handleInput(elem.id, event)} value={elem.adhaar_no}/></td>
  //         <td><input type="text" name="adhaar_card" onChange={event => handleInput(elem.id, event)} value={elem.adhaar_card}/></td>
  //         <td><input type="text" name="message" onChange={event => handleInput(elem.id, event)} value={elem.message}/></td> 
  //         <td>
          
  //             <div>
  //               <button type="button" className="bg-green-600 px-5 py-2" onClick={() => handleEdit(elem.id)}>
  //                 Update
  //               </button>
                
  //             </div>
          
  //         </td>
  //       </tr>
  //    )        
  // }

  const handleChange = (e) => {
    // const {name, value} = e.target;
    // setFormData((prevData) => (
    //   {...prevData, [name]: value}
    // ));
    setFormData({...formData, [e.target.name] : e.target.value})
  }





  var formBody = [];
  for (var property in formData) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(formData[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");



  const handleFormSubmit = (e) =>{
    e.preventDefault();
    console.log("form Data: ",formData);
    fetch('https://nimittech.com/sai_api/booking.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: formBody
    })


    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      phone_no: "",
      adhaar_no: "",
      adhaar_card: "",
      street: "",
      city: "",
      state: "",
      pin_code: "",
      arrival_date: "",
      arrival_time: "",
      no_of_person: "",
      room_type: "",
      message: ""
    });
    window.location.reload();
  }

  const [dashboardData, setDashboard] = useState([])
  const getTableApi = async(url) => {
     const res = await axios.get(url);
     const info = await res.data;
     setDashboard(...dashboardData, info)
  }

  // TABLE UPDATE THE DATA



  useEffect(()=>{
     getTableApi(APIData);
  },[])
  return (
    <Wrapper className='container'>
        <div className='text-right'>
          <button type="button" className="bg-[#f37e20] text-white py-2 px-4 rounded-md" data-bs-toggle="modal" data-bs-target="#exampleModal"><strong>Add Booking</strong></button>
         
          <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content bg-[#f8c301]">
              <form className='p-[25px]' onSubmit={handleFormSubmit}>
          <div className="relative flex flex-col md:flex-row justify-center items-center min-w-0 break-words w-full mb-3 text-center">
              <div>
                 <div> 
                 <div className='flex justify-between mb-[10px]'>
                      <div className='w-[45%]'>
                        <input type="text" className='w-full p-1 rounded-sm' name='first_name' value={formData.first_name} onChange={handleChange} placeholder="First Name" />
                      </div>
                      <div className='w-[45%]'>
                        <input type="text" className='w-full p-1 rounded-sm' name='last_name' value={formData.last_name} onChange={handleChange} placeholder='Last Name'/>
                      </div>
                    </div>
                     
                    <div className='flex justify-between mb-[10px]'>
                      <div className='w-[45%]'>
                        <input type="text" className='w-full p-1 rounded-sm' name='email' value={formData.email} onChange={handleChange} placeholder='Email' />
                      </div>
                      <div className='w-[45%]'>
                        <input type="text" className='w-full p-1 rounded-sm' name='phone_no' value={formData.phone_no} onChange={handleChange} placeholder='Phone Number'/>
                      </div>
                    </div>

                    <div className='mb-[10px]'>
                      <div>
                        <input className='w-full p-1 rounded-sm' onChange={handleChange} name='street' value={formData.street} type="text" placeholder='Street' />
                      </div>
                    </div>

                    <div className='d-flex justify-between mb-[10px]'>
                      <div className='w-[30%]'>
                        <input type="text" className='w-full p-1 rounded-sm' onChange={handleChange} name='city' value={formData.city} placeholder='City' />
                      </div>
                      <div className='w-[30%]'>
                        <input type="text" className='w-full p-1 rounded-sm' onChange={handleChange} name='state' value={formData.state} placeholder='State'/>
                      </div>
                      <div className='w-[30%]'>
                        <input type="text" className='w-full' name='pin_code' onChange={handleChange} value={formData.pin_code} placeholder='Pin'/>
                      </div>
                    </div>
                 </div>
                 <div>
                    <div className='flex justify-between mb-[10px]'>
                      <div className='w-[45%]'>
                        <input type="date" className='w-full p-1 rounded-sm' onChange={handleChange} name='arrival_date' value={formData.arrival_date} placeholder='' />
                      </div>
                      <div className='w-[45%]'>
                        <input type="text" className='w-full p-1 rounded-sm' onChange={handleChange} name='arrival_time' value={formData.arrival_time} placeholder='Arrival Time'/>
                      </div>
                    </div>

                    <div className='flex justify-between mb-[10px]'>
                      <div className='w-[45%]'>
                        <input type="text" className='w-full p-1 rounded-sm' onChange={handleChange} name='no_of_person' value={formData.no_of_person} placeholder='Number of Adults' />
                      </div>
                      <div className='w-[45%]'>
                        <input type="text" className='w-full p-1 rounded-sm' onChange={handleChange} name='room_type' value={formData.room_type} placeholder='Room Type'/>
                      </div>
                    </div>

                    <div className='flex justify-between mb-[10px]'>
                      <div className='w-[45%]'>
                        <input type="text" className='w-full p-1 rounded-sm' onChange={handleChange} name='adhaar_no' value={formData.adhaar_no}  placeholder='Aadhar Number' />
                      </div>
                      <div className='w-[45%]'>
                        <input type="file" className='w-full p-1 rounded-sm' onChange={handleChange} name='adhaar_card' value={formData.adhaar_card}/>
                      </div>
                    </div>

                    <div>
                      <div>
                      <textarea className='w-full p-1 rounded-sm' id="story" 
          rows="5" cols="33" placeholder='Message' name='message' onChange={handleChange} value={formData.message}/>
                      </div>
                     
                    </div>
                 </div>
              </div>
          </div>

          <div className="text-end px-4">
            <button
              className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
                
              </div>
            </div>
          </div>
        </div>
        <div className="tabl py-4 text-white">
        <div className="container overflow-auto h-[350px]">
        <form>
          <table className="table text-white" style={{tableLayout : "auto"}}>
            <thead>
              <tr className='bg-[#f37e20] sticky top-0'>
                <th className="text-nowrap">S.No.</th>
                <th className="text-nowrap">Devotte Name</th>
                <th className="text-nowrap">Email</th>
                <th className="text-nowrap">Mobile Number</th>
                <th className="text-nowrap">Street</th>
                <th className="text-nowrap">City</th>
                <th className="text-nowrap">State</th>
                <th className="text-nowrap">PinCode</th>
                <th className="text-nowrap">Arrival Date</th>
                <th className="text-nowrap">Arrival Time</th>
                <th className="text-nowrap">Number of Adults</th>
                <th className="text-nowrap">Room Type</th>
                <th className="text-nowrap">Aadhar Number</th>
                <th className="text-nowrap">Aadhar Image</th>
                <th className="text-nowrap">Message</th>
                {/* <th className="text-nowrap">
                   
                </th> */}
              </tr>
            </thead>
            <tbody>
               {
                 dashboardData.map((elem) =>(
                  // updateState === elem.id ? <Edit elem= {elem}/> :
                 
                  <>
                   <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                      <td>{elem.id}</td>
                      <td>{elem.first_name} {elem.last_name}</td>
                      <td>{elem.email}</td>
                      <td>{elem.phone_no}</td>
                      <td>{elem.street}</td>
                      <td>{elem.city}</td>
                      <td>{elem.state}</td>
                      <td>{elem.pin_code}</td>
                      <td>{elem.arrival_date}</td>
                      <td>{elem.arrival_time}</td>
                      <td>{elem.no_of_person}</td>
                      <td>{elem.room_type}</td>
                      <td>{elem.adhaar_no}</td>
                      <td>{elem.adhaar_card}</td>
                      <td>{elem.message}</td>
                      {/* <td>
                      
                          <div>
                            <button type="button" className="bg-green-600 px-5 py-2">
                              Edit
                            </button>
                            
                        
                          </div>
                       
                      </td> */}
                    </tr>
                  </>
                 ))
               }
              
            </tbody>
          </table>
        </form>
        </div>


        </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
.tableForm{
    position: fixed;
    top: 133px;
    right: 260px;
    background: green;
    padding: 30px;
}
   /* .dash1{
      background: linear-gradient(90deg, #FBDA61 0%, #FDECB0 100%);
   }
   table.scrolldown {
         width: 100%;
         border-spacing: 0;
         border: 1px solid black;
      }
          
      table.scrolldown tbody, table.scrolldown thead {
         display: block;
      }   
           
      table.scrolldown tbody {
         height: 250px; 
         overflow-y: auto;
         overflow-x: hidden; 
      }
          
     th{
      width : 186px;
     }
          
      tbody td {
         width : 186px;
         border-right: 1px solid black;
      }
      td {
         text-align:center;
      }
      .btn{
        background: #000;
      } */
      td{
        border: 1px solid #f1f1f1;
      }


`
export default ProductContent