import React from 'react'

const Donation = () => {
  return (
    <div className="container">
         <div className="container overflow-auto h-[350px]">
        <table className="table text-white" style={{tableLayout : "auto"}}>
            <thead>
              <tr className='bg-[#f37e20] sticky top-0'>
                <th className="text-nowrap">S.No.</th>
                <th className="text-nowrap">Devotte Name</th>
                <th className="text-nowrap">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>1</td>
                <td>Rohit Singh</td>
                <td>25,000</td>
              </tr>

              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>1</td>
                <td>Rohit Singh</td>
                <td>25,000</td>
              </tr>

              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>1</td>
                <td>Rohit Singh</td>
                <td>25,000</td>
              </tr>

              <tr className='hover:bg-slate-600 hover:cursor-pointer'>
                <td>1</td>
                <td>Rohit Singh</td>
                <td>25,000</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  )
}

export default Donation